export const mock_move = {
    transferee: {
        email: `crm-external@smooved.com`,
        phoneNumber: '0477778892',
        firstName: 'Hendrik',
        lastName: 'SCHUTTE',
        gender: 'female',
        language: 'en',
        movingAddress: {
            street: 'Straat',
            houseNumber: '1',
            city: 'Brussels',
            zipCode: '1000',
            country: 'Belgium',
        },
        transfereeType: 'owner-transferee',
        nationalNumber: '12415434563',
    },
    leaver: {
        email: `crm-external-leaver@smooved.com`,
        firstName: 'Jean',
        lastName: 'Smitter',
        gender: 'male',
        phoneNumber: '0477778899',
        language: 'nl',
        leaverType: 'tenant-leaver'
    },
    energyOffer: {
        energyType: 'both',
        eanCodeGas: 'G-123',
        gasMeterReading: '1234',
        eanCodeElectricity: 'E-321',
        electricitySingleMeterReading: '4321',
        electricityDoubleExclusiveNightMeterReading: '0',
    },
    property: {
        homeDescription: 'apartment',
        homeDescriptionApartmentLevel: 3,
    },
};
