import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CrmClient } from '@app/client/constants/client.constants';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { StorageSandbox } from '@app/storage/sandboxes/storage.sandbox';
import { environment } from '@environments/environment';
import * as mock from '@mock/webhook-move';
import { mergeMap } from 'rxjs/operators';
import { LocalStorageKeys } from '../../../storage/sandboxes/storage.constants';

@Component({
    selector: 'app-trigger-webhook',
    template: `
        <div class="__container u-padding u-bordered u-background-white">
            <h3 class="u-margin-bottom">Test Client Webhook</h3>
            <form [formGroup]="clientForm" (ngSubmit)="triggerWebhook()" class="u-flex-column">
                <ui-dropdown-input formControlName="clientId" label="clientId" [options]="clientIdOptions"></ui-dropdown-input>
                <app-text-input formControlName="clientSecret" label="clientSecret"></app-text-input>
                <app-text-input
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                    formControlName="externalUserId"
                    label="externalUserId"
                ></app-text-input>
                <app-button type="submit">{{ 'Trigger webhook' }}</app-button>
            </form>
        </div>
    `,
    styleUrls: ['./trigger-webhook.component.scss'],
})
export class TriggerWebhookComponent {
    public clientForm = this.formBuilder.group({
        clientId: [null, Validators.required],
        clientSecret: [null, Validators.required],
        externalUserId: [null, Validators.required],
    });

    public clientIdOptions: DropdownInput<string>[] = Object.values(CrmClient).map((client) => ({
        id: `client-${client}`,
        value: client,
        name: 'client',
        label: client,
    }));

    constructor(
        private http: HttpClient,
        private formBuilder: UntypedFormBuilder,
        private storageSandbox: StorageSandbox
    ) {}

    public triggerWebhook(): void {
        if (this.clientForm.valid) {
            this.storageSandbox.deleteToken(LocalStorageKeys.Token);
            const move: any = mock.mock_move;

            this.http
                .post<any>(`${environment.apiUri}/oauth/token`, {
                    grant_type: 'client_credentials',
                    client_id: this.clientForm.value.clientId,
                    client_secret: this.clientForm.value.clientSecret,
                })
                .pipe(
                    mergeMap((res) =>
                        this.http.post<{ redirectUrl: string }>(
                            `${environment.apiUri}/webhooks/incoming/${this.clientForm.value.clientId}`,
                            {
                                requestId: 'test-request-id',
                                action: 'request',
                                move,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${res.access_token}`,
                                    'External-user-id': this.clientForm.value.externalUserId,
                                },
                            }
                        )
                    )
                )
                .subscribe((res) => window.location.replace(res.redirectUrl));
        }
    }
}
